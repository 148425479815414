import LocalStorage from './LocalStorage';
import UserService from './UserService';

const ACCESS_TOKEN = 'idt';

const Auth = {
  timeoutId: null,
  get token() {
    return LocalStorage.get(ACCESS_TOKEN);
  },
  set token(token) {
    LocalStorage.set(ACCESS_TOKEN, token);
  },
  remove() {
    if (Auth.timerId) {
      clearTimeout(Auth.timerId);
    }
    LocalStorage.remove(ACCESS_TOKEN);
  },
  get isLoggedIn() {
    return !!this.token;
  },
  setTokens(data) {
    Auth.token = data.accessToken;
    Auth.setRefreshTimer(data.expiresIn);
  },
  setRefreshTimer(time) {
    if (Auth.timerId) {
      clearTimeout(Auth.timerId);
    }
    Auth.timerId = setTimeout(async () => {
      try {
        const resp = await UserService.refreshTokens();
        Auth.setTokens(resp);
      } catch (err) {
        // could not refresh... Logout should be handled by response interceptor
        // Auth.logout();
      }
    }, time - 5);
  },
  set timerId(timeoutId) {
    Auth.timeoutId = timeoutId;
  },
  get timerId() {
    return Auth.timeoutId;
  },
  async logout() {
    try {
      await UserService.logout();
      Auth.remove();
    } catch (err) {
      console.log('logout error', err);
    }
  },
};

export default Auth;
