import { isArray, isEmpty, isObject, isFinite } from 'lodash';
import moment from 'moment';
import * as EmailValidator from 'email-validator';

const errorStrings = {
  required: field => `Campul ${field} este necesar`,
  email: () => 'Emailul nu este valid',
  whitelist: 'Doar uramtoarele caractere speciale sunt acceptate: ',
  blacklist: 'Urmatoarele caractere nu sunt acceptate: < > ( ) # \' "',
};

const emailRx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const passwordRx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
const urlRx = /(https?:\/\/)?[\w-~]+(\.[\w-~]+)+(\/[\w-~]*)*(#[\w-]*)?(\?.*)?/;
const blacklistRx = /[<>()#'"]/ig;
const whitelistRx = /^[a-z0-9 ,.'-]+$/ig;

const validateRule = {
  required(value) {
    if (isObject(value)) {
      return !isEmpty(value);
    }
    return !!value;
  },
  email(value) {
    if (!value) {
      // don't test for empty values, 'required' will take care of this
      return true;
    }
    return EmailValidator.validate(value);
  },
  password(value) {
    if (!value) {
      return true;
    }
    return passwordRx.test(value);
  },
  url(value) {
    if (!value) {
      return true;
    }
    return urlRx.test(value.toLowerCase());
  },
  number(value) {
    if (!value) {
      return true;
    }
    return isFinite(+value);
  },
  eq(value, comparator) {
    if (!value) {
      return false;
    }
    return value.toString() === comparator.toString();
  },
  lt(value, comparator) {
    if (!value) {
      return true;
    }
    return value.toString().length < +comparator;
  },
  gt(value, comparator) {
    if (!value) {
      return false;
    }
    return value.toString().length > +comparator;
  },
  len(value, comparator) {
    if (!value) {
      return false;
    }
    return value.length === +comparator;
  },
  blacklist(value) {
    if (!value) {
      return false;
    }
    return blacklistRx.test(value) ? errorStrings.blacklist : false;
  },
};

const ValidationService = {
  validate(fieldRules, data) {
    if (!isArray(fieldRules)) {
      console.error('validation rules need to be array');
      return false;
    }
    const errors = {};
    fieldRules.forEach((vField) => {
      vField.rules.some((rule) => {
        let vRule = rule;
        let comparator;
        if (vRule.indexOf('-') > -1) {
          [vRule, comparator] = vRule.split('-');
        }
        if (!validateRule[vRule]) {
          return false;
        }
        const isValid = validateRule[vRule](data[vField.field], comparator);
        if (!isValid) {
          errors[vField.field] = errors[vField.field] || {};
          if (vField.message && vField.message[vRule]) {
            errors[vField.field] = vField.message[vRule];
          } else {
            errors[vField.field][vRule] = true;
          }
        }
        return !isValid;
      });
    });
    return !isEmpty(errors) ? errors : {};
  },

  dateIsAfter(current, threshold) {
    const yesterday = moment().subtract(threshold || 1, 'day');
    const date = moment.isMoment(current) ? current : moment(current);
    return date.isAfter(yesterday);
  },

  dateIsAfterYesterday(date) {
    return this.dateIsAfter(date, 1);
  },

  rules: validateRule,
};

export default ValidationService;
