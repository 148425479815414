import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Slide from '@material-ui/core/Slide';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}
function Toast({ open, text, onClose }) {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      autoHideDuration={4000}
      onClose={onClose}
      TransitionComponent={SlideTransition}
    >
      <SnackbarContent
        message={text}
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
        }}
      />
    </Snackbar>
  );
}

export default Toast;

Toast.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClose: PropTypes.func.isRequired,
};

Toast.defaultProps = {
  text: '',
};
