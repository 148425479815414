import { cardTitle } from 'shared/mdr/assets/jss/material-dashboard-react';

import cardImagesStyles from 'shared/mdr/assets/jss/material-dashboard-react/cardImagesStyles';

const styles = {
  ...cardImagesStyles,
  cardTitle: {
    ...cardTitle,
    marginTop: '0px',
    marginBottom: '3px',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: '0px',
    marginBottom: '3px',
    textAlign: 'center',
    maxHeight: '50px',
    overflow: 'hidden',
    fontSize: '1.15em',
  },
  cardCategory: {
    color: '#999999',
    fontSize: '14px',
    paddingTop: '10px',
    marginBottom: '0',
    marginTop: '0',
    margin: '0',
  },
  cardProductDescription: {
    textAlign: 'center',
    color: '#999999',
    height: '65px',
    overflow: 'hidden',
  },
  price: {
    color: 'inherit',
    '& h4': {
      marginBottom: '0px',
      marginTop: '0px',
    },
  },
  textCenter: {
    alignSelf: 'center',
  },
};

export default styles;
