import http from './http';

const EventService = {
  getUserEvents() {
    return http.get('/events');
  },
  getPlanChanges() {
    return http.get('/events/plans');
  },
  createEvent(data) {
    return http.post('/event', data);
  },
  updateEvent(data) {
    return http.put('/event', data);
  },
  getEvent(eventId) {
    return http.get(`/event/${eventId}`);
  },
  getEventTasks(eventId) {
    return http.get(`/event/${eventId}/tasks`);
  },
  getEventSummary(eventId) {
    return http.get(`/event/${eventId}/summary`);
  },
  getEventTypes() {
    return http.get('/eventTypes');
  },
  createDemoEvent(data) {
    return http.post('/demoEvent', data);
  },
  createEventFromDemo(data) {
    return http.post('/event/fromDemo', data);
  },
  deleteEvent(eventId) {
    return http.delete(`/event/${eventId}`);
  },
  updateTeam(toAdd, toRemove, eventId, owner) {
    return http.post('/event/team', {
      toAdd,
      toRemove,
      eventId,
      owner,
    });
  },
  getTeamUser(teamToken) {
    return http.get(`/event/team/${teamToken}`);
  },
  addTeamMember(data) {
    return http.post('/event/team/add-member', data);
  },
  exitTeam(eventId, userId) {
    return http.post('/event/team/remove-member', { eventId, userId });
  },
};

export default EventService;
