import axios from 'axios';
import Auth from './AuthToken';
import UserService from './UserService';

const http = axios.create({
  baseURL: '/api',
  withCredentials: true,
});

http.interceptors.request.use((config) => {
  const { token } = Auth;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

http.interceptors.response.use((response) => response.data, (error) => {
  if (error.response && error.response.status === 401) {
    const { url } = error.response.config;
    // try to refresh the tokens
    if (url.indexOf('auth/token') === -1) {
      return UserService.refreshTokens()
        .then((response) => {
          Auth.setTokens(response);

          // retry failed request
          const { config } = error;
          return http({
            ...config,
            url: config.url.replace(config.baseURL, ''),
          });
        })
        .catch((err) => {
          // token refresh failed
          Auth.remove();
          window.location = '/auth/login';
        });
    }

    // don't redo logout
    if (url.indexOf('logout') === -1) {
      Auth.logout();
    }

    Auth.remove();
    window.location = '/auth/login';
  }
  return Promise.reject(error);
});

export default http;
