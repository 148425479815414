import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { DndProvider } from 'react-dnd-multi-backend';
import { HTML5toTouch } from 'rdndmb-html5-to-touch';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core';
import { PrivateRoute, CookieWarn, AppLoader } from 'shared/components';
import Helmet from 'react-helmet';

import 'shared/material-kit-react/src/assets/scss/material-kit-pro-react.scss';
import 'assets/main.scss';

import { ChakraProvider } from '@chakra-ui/react';
import theme, { chakraTheme } from '../config/theme';

const Homepage = lazy(() => import('features/homepage/Homepage1'));
const SupplierHomepage = lazy(() => import('features/homepage/Supplier'));
const AuthPage = lazy(() => import('features/auth/AuthPage'));
const DashboardPage = lazy(() => import('features/dashboard/DashboardPage'));
const Dashboard = lazy(() => import('features/dashboard/Dashboard'));
const AdminPage = lazy(() => import('features/admin/AdminPage'));
const SuppliersPage = lazy(() => import('features/search/SuppliersPage'));
const SupplierProfile = lazy(() => import('features/supplier/profile/PublicProfile'));
const DemoPage = lazy(() => import('features/demo/DemoPage'));
const LegalPage = lazy(() => import('features/legal/LegalPage'));
const PublicInvitation = lazy(() => import('features/invitation/public/Invitation'));
const NotFoundPage = lazy(() => import('shared/components/404/NotFoundPage'));
const ContactPage = lazy(() => import('features/contact/Contact'));
const EstimatePage = lazy(() => import('features/estimate/EstimatePage'));
const EstimateResultsPage = lazy(() => import('features/estimate/EstimateResultsPage'));

const routes = [{
  path: '/auth',
  component: AuthPage,
  name: 'Auth',
}, {
  path: '/u/event/:eventId',
  component: Dashboard,
  name: 'DashboardPage',
  private: true,
}, {
  path: '/u',
  component: Dashboard,
  name: 'DashboardPage',
  private: true,
}, {
  path: '/cauta/furnizori',
  component: SuppliersPage,
  name: 'SuppliersPage',
}, {
  path: '/furnizor/:supplierSlug',
  exact: true,
  component: SupplierProfile,
  name: 'SupplierProfile',
}, {
  path: '/',
  exact: true,
  component: Homepage,
  name: 'Homepage',
  private: true,
}, {
  path: '/admin',
  exact: true,
  component: AdminPage,
  name: 'Admin',
  private: true,
}/*, {
  path: '/legal/:section',
  component: LegalPage,
  name: 'LegalPage',
}*/, {
  path: '/organizare-eveniment/:step?',
  component: DemoPage,
  name: 'DemoPage',
}, {
  path: '/invitatie/:inviteId',
  component: PublicInvitation,
  name: 'PublicInvitation',
}, {
  path: '/contact',
  component: ContactPage,
  name: 'ContactPage',
}, {
  path: '/pentru-furnizori',
  component: SupplierHomepage,
  name: 'SupplierHomepage',
}, {
  path: '/calculator-buget-nunta',
  component: EstimateResultsPage,
  name: 'EstimatePage',
}, {
  path: '/cat-costa-nunta/:location?',
  component: EstimatePage,
  name: 'EstimatePage',
}];

function App () {
  const agreed = !!window.localStorage.getItem('agreedCookies');
  return (
    <DndProvider options={HTML5toTouch}>
      <Helmet>
        <title>EpicPlan - prietenul tau in organizarea unor evenimente de poveste</title>
        <meta name="description" content="Planifica si organizeaza evenimentul perfect cu ajutorul aplicatiei EpicPlan. Ai la dispozitie instrumente precum: lista de activitati, bugete, lista de invitati si furnizori de servicii. Incepe acum!" />
        <meta property="og:url" content="https://epicplan.ro" />
        <meta property="og:title" content="EpicPlan - prietenul tau in organizarea unor evenimente de poveste" />
        <meta property="og:description" content="Planifica si organizeaza evenimentul perfect cu ajutorul aplicatiei EpicPlan. Ai la dispozitie instrumente precum: lista de activitati, bugete, lista de invitati si furnizori de servicii. Incepe acum!" />
        <meta property="og:image" content="https://epicplan.ro/assets/img/FB_cover.png" />
        <link rel="canonical" href="https://epicplan.ro/" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ChakraProvider theme={chakraTheme}>
          <Suspense fallback={<AppLoader />}>
            <Switch>
              {routes.map((route) => (
                route.private
                  ? (
                    <PrivateRoute
                      component={route.component}
                      path={route.path}
                      exact={route.exact}
                      key={route.name}
                    />
                  )
                  : (
                    <Route
                      component={route.component}
                      path={route.path}
                      exact={route.exact}
                      key={route.name}
                    />
                  )
              ))}
              <Route component={NotFoundPage} />
            </Switch>
          </Suspense>
          {!agreed && <CookieWarn />}
        </ChakraProvider>
      </ThemeProvider>
    </DndProvider>
  );
}

export default App;
