import React from 'react';
import PropTypes from 'prop-types';
import ScaleLoader from 'react-spinners/ScaleLoader';
import withStyles from '@material-ui/core/styles/withStyles';
import { infoColor, dangerColor } from 'assets/jss/colors';
import LogoRed from 'assets/img/logoRed.png';
const styles = {
  spinContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    backgroundColor: 'rgba(0, 0, 0, .2)',
  },
};

const AppLoader = ({ classes }) => {
  return (
    <section className={classes.spinContainer}>
      <div style={{ marginBottom: 30 }}>
        <img src={LogoRed} width="130" alt="EpicPlan" />
      </div>
      <ScaleLoader color={dangerColor} height={75} width={10} />
    </section>
  );
};

AppLoader.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(AppLoader);
