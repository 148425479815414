import http from './http';

const UserService = {
  login(data) {
    return http.post('/auth/login', data);
  },
  loginFb(data) {
    return http.post('/auth/fblogin', data);
  },
  loginG(data) {
    return http.post('/auth/glogin', data);
  },
  logout() {
    return http.get('/auth/logout');
  },
  refreshTokens() {
    return http.get('/auth/token');
  },
  register(data) {
    return http.post('/auth/create', data);
  },
  registerSupplier(data) {
    return http.post('/auth/createSupplier', data);
  },
  profile() {
    return http.get('/profile');
  },
  getSubscription() {
    return http.get('/subscription');
  },
  updateUser(user) {
    return http.put('/user/update', user);
  },
  emailVerify(code) {
    return http.post('/auth/verify', { code });
  },
  resendVerify(email) {
    return http.post('/auth/verify/resend', { email });
  },
  forgotPassword(email) {
    return http.post('/auth/forgot-password', { email });
  },
  resetPassword(data) {
    return http.post('/auth/reset-password', data);
  },
  uploadImages(data, progress) {
    return http.post('/upload-images', data, {
      onUploadProgress: progress,
    });
  },
  sendFeedback(data) {
    return http.post('/feedback', data);
  },
  changePassword(data) {
    return http.post('/change-password', data);
  },
  deleteAccount(id) {
    return http.delete(`/user/delete-account/${id}`);
  },
  getPlans(type) {
    return http.get(`/plan/by-type/${type}`);
  },
  generatePayment(data) {
    return http.post('/payment/create', data);
  },
  confirmPayment(data) {
    return http.post('/payment/confirm', data);
  },
  getTransaction(transactionId) {
    return http.get(`/payment/transaction/${transactionId}`);
  },
  getTransactions() {
    return http.get('/payment/transactions');
  },
  getInvoice(transactionId) {
    return http.get(`/payment/invoice/${transactionId}`);
  },
};

export default UserService;
