import { createActions, handleActions } from 'redux-actions';
import { decode } from 'ent';
import InvitationService from './service';

const actionCreators = createActions({
  INVITATION: {
    REQUEST: () => ({ isLoading: true }),
    ERROR_RESPONSE: (data) => {
      if (data.response.status === 404) {
        return null;
      }
      return { ...data };
    },
    GET_INVITATION_RESP: data => ({ ...data }),
    CREATE_INVITATION_RESP: data => ({ ...data }),
    UPDATE_INVITATION_DATA: data => ({ ...data }),
  },
});

const {
  request,
  errorResponse,
  getInvitationResp,
  createInvitationResp,
  updateInvitationData,
} = actionCreators.invitation;

export const invitationReducer = handleActions({
  [request](state, action) {
    return { ...state, ...action.payload };
  },
  [errorResponse](state, action) {
    return {
      ...state,
      isLoading: false,
      error: action.payload,
    };
  },
  [getInvitationResp](state, action) {
    return {
      ...state,
      isLoading: false,
      invitation: {
        ...action.payload,
        content: decode(action.payload.content),
        title: decode(action.payload.title),
      },
      error: null,
    };
  },
  [createInvitationResp](state, action) {
    return {
      ...state,
      isLoading: false,
      invitation: action.payload,
      error: null,
    };
  },
  [updateInvitationData](state, action) {
    return {
      ...state,
      invitation: {
        ...state.invitation,
        ...action.payload,
        content: decode(action.payload.content || ''),
        title: decode(action.payload.title || ''),
      },
      isLoading: false,
      error: null,
    };
  },
}, {
  isLoading: false,
  invitation: {},
  error: null,
});

export const getEventInvitation = event =>
  async function (dispatch, getState) {
    let { invitation } = getState().invitationReducer;
    if (invitation.event && invitation.event === event) {
      dispatch(getInvitationResp(invitation));
      return;
    }
    dispatch(request());
    try {
      invitation = await InvitationService.getEventInvitation(event);
      dispatch(getInvitationResp(invitation));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const setInvitationData = data =>
  function (dispatch) {
    dispatch(updateInvitationData(data));
  };

export const createInvitation = data =>
  async function (dispatch) {
    dispatch(request());
    try {
      const invitation = await InvitationService.createInvitation(data);
      dispatch(createInvitationResp(invitation));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const updateInvitation = data =>
  async function (dispatch) {
    dispatch(request());
    try {
      const invitation = await InvitationService.updateInvitation(data);
      dispatch(updateInvitationData(invitation));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

