import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import { u1F48F } from 'react-icons-kit/noto_emoji_regular/u1F48F';
import { u1F382 } from 'react-icons-kit/noto_emoji_regular/u1F382';
import { u1F37C } from 'react-icons-kit/noto_emoji_regular/u1F37C';
import { u1F378 } from 'react-icons-kit/noto_emoji_regular/u1F378';

const iconTypes = {
  wedding: u1F48F,
  birthday: u1F382,
  christening: u1F37C,
  corporate: u1F378,
};

function EventIcon({ type, size, color }) {
  return (
    <div style={{ display: 'inline-block', color }}>
      <Icon size={size} icon={iconTypes[type]} color={color} />
    </div>
  );
}

export default EventIcon;

EventIcon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

EventIcon.defaultProps = {
  size: 24,
  color: 'inherit',
};

