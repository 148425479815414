import { createActions, handleActions } from 'redux-actions';
import TaskService from './taskService';
import he from 'he';

const {
  request,
  create,
  update,
  remove,
} = createActions({
  REQUEST: () => ({ isLoading: true }),
  CREATE: data => ({ ...data }),
  UPDATE: data => ({ ...data }),
  REMOVE: data => ({ ...data }),
  GET_TASK: data => ({ ...data }),
});

export const taskReducer = handleActions({
  [request](state, action) {
    return { ...state, ...action.payload };
  },
  [create](state, action) {
    return {
      ...state,
      isLoading: false,
      currentTask: {
        ...action.payload,
        description: he.decode(action.payload.description || ''),
      },
    };
  },
  [update](state, action) {
    return {
      ...state,
      isLoading: false,
      currentTask: {
        ...action.payload,
        description: he.decode(action.payload.description || ''),
      },
    };
  },
  [remove](state, action) {
    return {
      ...state,
      isLoading: false,
      currentTask: null,
      ...action.payload,
    };
  },
}, {
  isLoading: false,
  currentTask: null,
});

export const setTaskData = task => dispatch => dispatch(update(task));
export const addTask = task => dispatch => dispatch(create(task));
export const removeTask = task => dispatch => dispatch(remove(task));

export const updateTask = data =>
  async function (dispatch) {
    dispatch(request());
    try {
      const newTask = await TaskService.updateTask(data);
      dispatch(update(newTask));
    } catch (err) {
      dispatch(update(err));
    }
  };

export const createTask = data =>
  async function (dispatch) {
    dispatch(request());
    try {
      const newTask = await TaskService.createTask(data);
      dispatch(create(newTask));
    } catch (err) {
      dispatch(create(err));
    }
  };

export const deleteTask = taskId =>
  async function (dispatch) {
    dispatch(request());
    try {
      await TaskService.deleteTask(taskId);
      dispatch(remove());
    } catch (err) {
      dispatch(remove(err));
    }
  };
