import http from 'shared/services/http';

export default class SupplierService {
  static getById(suppId) {
    return http.get(`/supplier/${suppId}`);
  }
  static getProducts(suppId) {
    return http.get(`/supplier/${suppId}/products`);
  }
  static updateSupplier(data) {
    return http.put('/supplier', data);
  }
  static removeImage(imageId, supplierId) {
    return http.delete(`/supplier/${supplierId}/image/${imageId}`);
  }
  static getBySlug(suppSlug) {
    return http.get(`/supplier/slug/${suppSlug}`);
  }
  static getReviews(prodId) {
    return http.get(`/reviews/${prodId}`);
  }
  static addReview(review) {
    return http.post('/review', review);
  }
  static validateReview(data) {
    return http.post('/review/validate', data);
  }
};