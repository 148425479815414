import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { AuthToken } from 'shared/services';

const PrivateRoute = ({
  path, exact, component: Component, ...rest
}) => (
  <Route path={path} exact={exact}>
    {AuthToken.isLoggedIn
      ? <Component {...rest} />
      : <Redirect to="/auth/login" />}
  </Route>
);

export default PrivateRoute;

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  component: PropTypes.node.isRequired,
};

PrivateRoute.defaultProps = {
  exact: false,
};
