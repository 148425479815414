import { BehaviorSubject } from 'rxjs';
import { UserService, AuthToken } from 'shared/services';
import { isEmpty } from 'lodash';
import he from 'he';

const userPlans = ['USER_1', 'USER_2', 'USER_3'];
const supplierPlans = ['SUPPLIER_0', 'SUPPLIER_1', 'SUPPLIER_2', 'SUPPLIER_3'];

const UserStore = {
  current$: new BehaviorSubject(null),
  accessData$: new BehaviorSubject(null),
  subscription$: new BehaviorSubject({}),
  plans$: new BehaviorSubject([]),
  transactions$: new BehaviorSubject([]),
  transactionsLoading$: new BehaviorSubject(false),
  error$: new BehaviorSubject(null),
  isLoading$: new BehaviorSubject(false),
  async login(data) {
    try {
      this.isLoading$.next(true);
      const loggedIn = await UserService.login(data);
      this.accessData$.next(loggedIn);
      this.error$.next(null);
      return loggedIn;
    } catch (err) {
      this.error$.next({
        login: err,
      });
      return err;
    }
  },
  async register(data) {
    try {
      const {
        accessToken,
        createdAt,
        expiresIn,
        user,
      } = await UserService.register(data);
      this.accessData$.next({
        accessToken,
        createdAt,
        expiresIn,
      });
      this.current$.next(user);
      this.error$.next(null);
    } catch (err) {
      this.error$.next({
        register: err,
      });
    }
  },
  async logout() {
    try {
      await UserService.logout();
      await AuthToken.logout();
    } catch (err) {
      this.error$.next({
        logout: err,
      });
    }
  },
  async registerSupplier(data) {
    try {
      const { accessToken, createdAt, expiresIn } = await UserService.registerSupplier(data);
      this.accessData$.next({
        accessToken,
        createdAt,
        expiresIn,
      });
      this.error$.next(null);
    } catch (err) {
      this.error$.next({
        register: err,
      });
    }
  },
  async getUserProfile() {
    try {
      const user = await UserService.profile();
      if (!isEmpty(user) && user.supplierData) {
        user.supplierData = {
          ...user.supplierData,
          name: he.decode(user.supplierData.name || ''),
          description: he.decode(user.supplierData.description || ''),
          facilities: user.supplierData.facilities.length
            && user.supplierData.facilities.map((facility) => he.decode(facility || '')),
        };
      }
      this.current$.next(user);
      this.error$.next(null);
    } catch (err) {
      this.error$.next({
        profile: err,
      });
    }
  },
  async getUserSubscription() {
    try {
      const subscription = await UserService.getSubscription();
      this.subscription$.next(subscription);
    } catch (err) {
      this.error$.next({
        subscription: err,
      });
    }
  },
  async getUserEvents() {
    try {
      // const events = Ev
    } catch (err) {
      this.error$.next({
        getEvents: err,
      });
    }
  },
  async fbLogin(data) {
    try {
      const user = UserService.loginFb(data);
      this.accessData$.next(user);
      this.error$.next(null);
    } catch (err) {
      this.error$.next({
        login: err,
      });
    }
  },
  async gLogin(data) {
    try {
      const user = await UserService.loginG(data);
      this.accessData$.next(user);
      this.error$.next(null);
    } catch (err) {
      this.error$.next({
        login: err,
      });
    }
  },
  async updateUser(data) {
    try {
      const user = await UserService.updateUser(data);
      this.current$.next(user);
      this.error$.next(null);
    } catch (err) {
      this.error$.next({
        update: err,
      });
    }
  },
  async deleteAccount() {
    try {
      await UserService.deleteAccount(this.current$.getValue()._id);
      this.current$.next(null);
      this.accessData$.next(null);
      await AuthToken.logout();
    } catch (err) {
      this.error$.next({
        delete: err,
      });
    }
  },
  async logoutUser() {
    try {
      await UserService.logout();
      this.current$.next(null);
      this.accessData$.next(null);
      await AuthToken.logout();
    } catch (err) {
      this.error$.next({
        logout: err,
      });
    }
  },
  async getPlans() {
    try {
      const currentUser = this.current$.getValue();
      const type = currentUser.supplier ? 'supplier' : 'event';
      const plans = await UserService.getPlans(type);
      this.plans$.next(plans);
      return plans;
    } catch (err) {
      this.error$.next({
        plans: err,
      });
    }
  },
  async getTransactions() {
    this.transactionsLoading$.next(true);
    try {
      const transactions = await UserService.getTransactions();
      this.transactions$.next(transactions);
    } catch (err) {
      this.error$.next({
        transactions: err,
      });
    }
    this.transactionsLoading$.next(false);
  },
};

UserStore.subscription$.subscribe({
  next: (value) => {

  },
});

UserStore.plans$.subscribe({
  next: (value) => {
    const currentUser = UserStore.current$.getValue();
  },
});

export default UserStore;
