const SocketMessage = {
  messages: [],
  subs: [],
  add: (message) => {
    SocketMessage.messages.push(message);
    onAction();
  },
  remove: (message) => {
    const idx = SocketMessage.messages.indexOf(message);
    SocketMessage.messages.splice(idx, 1);
    onAction();
  },
  sub: (cb) => {
    SocketMessage.subs.push(cb);
  },
  unsub: (idx) => {
    SocketMessage.subs.splice(idx, 1);
  },
};

const onAction = () => {
  SocketMessage.subs.forEach(cb => cb(SocketMessage.messages));
};

export default SocketMessage;
