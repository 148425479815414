import { createActions, handleActions } from 'redux-actions';
import isEmpty from 'lodash/isEmpty';
import ProductService from 'shared/services/ProductService';
import LocationsService from 'shared/services/LocationsService';
import SearchService from './service';

const actionCreators = createActions({
  SEARCH: {
    REQUEST: (data) => ({ isLoading: true, ...data }),
    ERROR_RESPONSE: (data) => ({ ...data }),
    GET_SUPPLIERS_RESP: (data) => ({ ...data }),
    GET_CATEGORIES_RESP: (data) => ([...data]),
    GET_LOCATION_DATA_RESP: (data) => ({ ...data }),
    SET_QUERY: (data) => ({ ...data }),
  },
});

const {
  request,
  errorResponse,
  getSuppliersResp,
  getCategoriesResp,
  getLocationDataResp,
  setQuery,
} = actionCreators.search;

export const searchReducer = handleActions({
  [request](state, action) {
    return { ...state, ...action.payload };
  },
  [errorResponse](state, action) {
    const data = action.payload.response ? action.payload.response.data : {};
    return {
      ...state,
      isLoading: false,
      error: data.message || data.errors,
    };
  },
  [getSuppliersResp](state, action) {
    return {
      ...state,
      queryLoading: false,
      isLoading: false,
      suppliers: action.payload.results,
      count: action.payload.count,
    };
  },
  [getCategoriesResp](state, action) {
    return {
      ...state,
      isLoading: false,
      categories: action.payload,
    };
  },
  [getLocationDataResp](state, action) {
    const { city, county } = action.payload;
    return {
      ...state,
      currentCity: city || {},
      currentCounty: county || {},
    };
  },
  [setQuery](state, action) {
    return {
      ...state,
      query: action.payload || {},
    };
  },
}, {
  isLoading: false,
  queryLoading: false,
  suppliers: [],
  count: null,
  categories: [],
  currentCity: {},
  currentCounty: {},
  query: {},
});

export const setFilterQuery = (query) => (dispatch) => dispatch(setQuery(query));

export const getSuppliers = (query) => async function (dispatch) {
  dispatch(request({ queryLoading: true }));
  try {
    const suppliers = await SearchService.querySuxppliers(query);
    dispatch(getSuppliersResp(suppliers));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const getCategories = () => async function (dispatch, getState) {
  const { categories } = getState().searchReducer;
  if (categories.length) {
    dispatch(getCategoriesResp(categories));
    return;
  }
  dispatch(request());
  try {
    const pCategories = await ProductService.getProductCategories();
    dispatch(getCategoriesResp(pCategories));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const getLocationData = (citySlug, countySlug) => async function (dispatch, getState) {
  const { currentCity, currentCounty } = getState().searchReducer;
  if (!isEmpty(currentCity) && !isEmpty(currentCounty)) {
    const locData = {};
    if (countySlug && countySlug === currentCounty.nameSlug) {
      locData.county = currentCounty;
    }
    if (citySlug && citySlug === currentCity.nameSlug) {
      locData.city = currentCity;
    }
    dispatch(getLocationDataResp(locData));
    return;
  }
  dispatch(request());
  try {
    const data = await LocationsService.getLocationData(citySlug, countySlug);
    dispatch(getLocationDataResp(data));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const setLocationData = (data) => (dispatch) => dispatch(getLocationDataResp(data));
