import http from './http';

export default class LocationsService {
  static getCounties() {
    return http.get('/counties');
  }
  static getCities(countyId) {
    return http.get(`/cities/${countyId}`);
  }
  static searchCities(value, includeCounty) {
    return http.get(`/city/search?city=${value}&county=${includeCounty ? 1 : 0}`);
  }
  static getLocationData(citySlug, countySlug) {
    let str = '/location/by-slug?';
    if (citySlug) {
      str += `city=${citySlug}`;
    }
    if (countySlug) {
      str += `${citySlug ? '&' : ''}county=${countySlug}`;
    }
    return http.get(str);
  }
}

