import { infoColor } from 'shared/mdr/assets/jss/material-dashboard-react';


const styles = theme => ({
  warnContainer: {
    position: 'fixed',
    zIndex: 10000,
    bottom: 0,
    right: 0,
    left: 0,
    minHeight: 50,
    backgroundColor: 'rgba(0, 0, 0, .8)',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 30px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: 10,
    },
  },
  agreeBtn: {

  },
  detailsLink: {
    color: infoColor[0],
    marginLeft: 10,
    '&:hover': {
      color: infoColor[2],
    },
  },
});

export default styles;
