import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import styles from './SelectStyle';

const CustomSelect = ({ classes, ...props }) => (
  <FormControl
    fullWidth
    className={`${props.className} ${classes.selectFormControl}`}
  >
    <InputLabel
      htmlFor={props.name}
      className={classes.selectLabel}
    >
      {props.placeholder}
    </InputLabel>
    <Select
      native
      MenuProps={{
        className: classes.selectMenu,
      }}
      classes={{
        select: classes.select,
      }}
      value={props.value}
      onChange={props.onChange}
      inputProps={{
        name: props.name,
        id: props.name,
        disabled: props.disabled,
      }}
    >
      <option value="" />
      {props.options.map(opt => (
        <option value={opt.value} key={opt.label}>{opt.label}</option>
      ))}
    </Select>
  </FormControl>
);

CustomSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

CustomSelect.defaultProps = {
  value: '',
  className: '',
  disabled: false,
};

export default withStyles(styles)(CustomSelect);

