import headerLinksStyle from 'shared/material-kit-react/src/assets/jss/material-kit-pro-react/components/headerLinksStyle';

const styles = theme => ({
  ...headerLinksStyle(theme),
  dropdownLink: {
    display: 'flex',
    padding: '8px 15px',
    textDecoration: 'none',
    '&:hover': {
      color: 'inherit',
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, .2)',
      cursor: 'pointer',
    },
    '& a:hover': {
      color: 'inherit',
    },
  }
});

export default styles;