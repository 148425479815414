import http from 'shared/services/http';

export default class GuestService {
  static createGuest(data) {
    return http.post('/guest', data);
  }

  static addGuests(data) {
    return http.post('/guests', data);
  }

  static getEventGuests(eventId) {
    return http.get(`/event/${eventId}/guests`);
  }

  static updateGuest(guest) {
    return http.put('/guest', guest);
  }

  static updateGuests(guests) {
    return http.put('/guests', { guests });
  }

  static deleteGuest(guestId) {
    return http.delete(`/guest/${guestId}`);
  }

  static deleteGuests(guests) {
    return http.post('/guests/delete', { guests });
  }

  static sendBulkInvite(guests) {
    return http.post('/guests/invite', { guests });
  }

  static getGuest(guestId) {
    return http.get(`/guest/${guestId}`);
  }
}
