import { createActions, handleActions } from 'redux-actions';
import isEmpty from 'lodash/isEmpty';
import he from 'he';

import UserService from 'shared/services/UserService';
import EventService from 'shared/services/EventService';
import AuthToken from 'shared/services/AuthToken';

// ACTION CREATORS
const actionCreators = createActions({
  USER: {
    REQUEST: () => ({ isLoading: true }),
    LOGIN_REQUEST: () => ({ isLoading: true }),
    LOGIN_RESPONSE: data => ({ ...data }),
    LOGOUT_REQUEST: () => ({ isLoading: true }),
    REGISTER_REQUEST: () => ({ isLoading: true }),
    REGISTER_RESPONSE: data => ({ ...data }),
    REGISTER_SUPPLIER_RESPONSE: data => ({ ...data }),
    PROFILE_REQUEST: () => ({ isLoading: true }),
    PROFILE_RESPONSE: data => ({ ...data }),
    UPDATE_USER_RESP: data => ({ ...data }),
    EVENTS_REQUEST: () => ({ eventsLoading: true }),
    EVENTS_RESPONSE: data => data,
    ERROR_RESPONSE: data => data,
  },
});
const {
  request,
  loginRequest,
  loginResponse,
  logoutRequest,
  registerRequest,
  registerResponse,
  registerSupplierResponse,
  profileRequest,
  profileResponse,
  eventsRequest,
  eventsResponse,
  errorResponse,
  updateUserResp,
} = actionCreators.user;

export const userReducer = handleActions({
  [request](state, action) {
    return { ...state, ...action.payload };
  },
  [loginRequest](state, action) {
    return { ...state, ...action.payload };
  },
  [errorResponse](state, action) {
    const { data } = action.payload.response;
    const error = data.message || data.errors;
    return {
      ...state,
      isLoading: false,
      error,
    };
  },
  [loginResponse](state, action) {
    return {
      ...state,
      isLoading: false,
      error: action.error,
      errorData: action.error ? action.payload.response.data : {},
      accessData: action.payload || {},
    };
  },
  [logoutRequest](state) {
    return {
      ...state,
      isLoading: false,
      user: {},
      accessData: {},
    };
  },
  [registerRequest](state, action) {
    return { ...state, ...action.payload };
  },
  [registerResponse](state, action) {
    const {
      accessToken,
      createdAt,
      expiresIn,
      user,
    } = action.payload || {};
    return {
      ...state,
      isLoading: false,
      error: action.error,
      errorMessage: action.error ? action.payload.response.data.message : null,
      user,
      registered: !action.error,
      accessData: {
        accessToken,
        createdAt,
        expiresIn,
      },
    };
  },
  [registerSupplierResponse](state, action) {
    const { accessToken, createdAt, expiresIn } = action.payload || {};
    return {
      ...state,
      isLoading: false,
      registered: !action.error,
      accessData: {
        accessToken,
        createdAt,
        expiresIn,
      },
    };
  },
  [profileRequest](state) {
    return { ...state, isLoading: true };
  },
  [profileResponse](state, action) {
    const user = !action.error ? action.payload : {};
    if (!isEmpty(user) && user.supplierData) {
      user.supplierData = {
        ...user.supplierData,
        name: he.decode(user.supplierData.name || ''),
        description: he.decode(user.supplierData.description || ''),
        facilities: user.supplierData.facilities.length &&
          user.supplierData.facilities.map(facility => he.decode(facility || '')),
      };
    }
    return {
      ...state,
      isLoading: false,
      error: action.error,
      errorMessage: action.error ?
        action.payload.response && action.payload.response.data.message :
        null,
      user: !action.error ? action.payload : {},
    };
  },
  [updateUserResp](state, action) {
    let supplier;
    if (!isEmpty(action.payload)) {
      supplier = action.payload.supplierData || state.user.supplierData;
    }
    return {
      ...state,
      isLoading: false,
      user: {
        ...action.payload,
        supplierData: { ...supplier },
      },
    };
  },
}, {
  isLoading: false,
  eventsLoading: false,
  error: null,
  errorMessage: '',
  accessData: {},
  user: {},
  events: [],
  registered: false,
});

export const loginUser = data =>
  async function (dispatch) {
    dispatch(loginRequest());
    try {
      const loggedIn = await UserService.login(data);
      dispatch(loginResponse(loggedIn));
    } catch (err) {
      dispatch(loginResponse(err));
    }
  };

export const registerUser = data =>
  async function (dispatch) {
    dispatch(registerRequest());
    try {
      const loggedIn = await UserService.register(data);
      dispatch(registerResponse(loggedIn));
    } catch (err) {
      dispatch(registerResponse(err));
    }
  };

export const logoutUser = () =>
  async function (dispatch) {
    try {
      await AuthToken.logout();
      dispatch(logoutRequest());
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const registerSupplier = data =>
  async function (dispatch) {
    dispatch(registerRequest());
    try {
      const registered = await UserService.registerSupplier(data);
      dispatch(registerSupplierResponse(registered));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const getUserProfile = () =>
  async function (dispatch, getState) {
    if (!isEmpty(getState().userReducer.user) || getState().userReducer.isLoading) {
      profileResponse(getState().userReducer.user);
      return;
    }
    dispatch(profileRequest());
    try {
      const userProfile = await UserService.profile();
      dispatch(profileResponse(userProfile));
    } catch (err) {
      dispatch(profileResponse(err));
    }
  };

export const getUserEvents = forceUpdate =>
  async function (dispatch, getState) {
    if (!forceUpdate && getState().userReducer.events.length) {
      eventsResponse(getState().userReducer.events);
      return;
    }
    dispatch(eventsRequest());
    try {
      const events = await EventService.getUserEvents();
      dispatch(eventsResponse(events));
    } catch (err) {
      dispatch(eventsResponse(err));
    }
  };

export const fbLogin = fbData =>
  async function (dispatch) {
    dispatch(loginRequest());
    try {
      const user = await UserService.loginFb(fbData);
      dispatch(loginResponse(user));
    } catch (err) {
      dispatch(loginResponse(err));
    }
  };

export const gLogin = fbData =>
  async function (dispatch) {
    dispatch(loginRequest());
    try {
      const user = await UserService.loginG(fbData);
      dispatch(loginResponse(user));
    } catch (err) {
      dispatch(loginResponse(err));
    }
  };

export const updateUserData = userData => dispatch => dispatch(updateUserResp(userData));

export const updateUser = userData =>
  async function (dispatch) {
    // dispatch(request());
    try {
      const user = await UserService.updateUser(userData);
      dispatch(updateUserResp(user));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };
