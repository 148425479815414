/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import flow from 'lodash/flow';

import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dropdown from 'shared/mdr/components/CustomDropdown/CustomDropdown';
import Button from 'shared/mdr/components/CustomButtons/Button';
import GeneralError from 'shared/components/GeneralError/GeneralError';

import Auth from 'shared/services/AuthToken';
import * as UserActions from 'shared/ducks/userDuck';
import * as EventActions from 'shared/ducks/eventDuck';

import styles from './NavLinksStyles';

class NavLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
    };
  }

  componentDidMount() {
    if (Auth.isLoggedIn) {
      this.props.actions.getUserProfile();
    }
  }

  logOut = async () => {
    try {
      await this.props.actions.logoutUser();
    } catch (err) {
      this.setState({
        showError: true,
      });
      return;
    }
    this.props.actions.cleanEventsData();
    window.location.reload();
  };

  hideError = () => {
    this.setState({
      showError: false,
    });
  };

  render() {
    const { classes, links, user } = this.props;
    const dropLinks = [
      <Link
        to="/u/dashboard"
        href="/dashboard"
        className={classes.dropdownLink}
      >
        Evenimentul meu
      </Link>,
      <Link
        to="/u/account"
        href="/account"
        className={classes.dropdownLink}
      >
        Contul meu
      </Link>,
      <Button
        size="sm"
        className={classes.dropdownLink}
        color="transparent"
        onClick={this.logOut}
      >
        Logout
      </Button>,
    ];
    if (user.supplier) {
      dropLinks.shift();
    }
    return (
      <React.Fragment>
        <List className={`${classes.list} ${classes.mlAuto}`}>
          {links.map((link, i) => (
            <React.Fragment key={i}>
              <ListItem className={classes.listItem}>
                <div
                  className={`${classes.navLink} ${classes.hover}`}
                >
                  {link}
                </div>
              </ListItem>
            </React.Fragment>
          ))}
          {Auth.isLoggedIn ?
            <ListItem className={classes.listItem}>
              <Dropdown
                noLiPadding
                navDropdown
                hoverColor="danger"
                buttonText={user && user.username}
                buttonProps={{
                  color: 'transparent',
                }}
                dropdownList={dropLinks}
              />
            </ListItem> :
            <ListItem className={classes.listItem}>
              <div
                className={`${classes.navLink} ${classes.hover}`}
              >
                <Link to="/auth/login" href="/auth/login">
                  Login
                </Link>
              </div>
            </ListItem>
          }
        </List>
        {this.state.showError &&
          <GeneralError onHide={this.hideError} />
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { userReducer } = state;
  return {
    user: userReducer.user,
  };
}

function mapDispatch(dispatch) {
  return { actions: bindActionCreators({ ...UserActions, ...EventActions }, dispatch) };
}

export default flow(
  connect(mapStateToProps, mapDispatch),
  withStyles(styles),
)(NavLinks);

NavLinks.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({})),
  user: PropTypes.shape({}),
  actions: PropTypes.shape({
    getUserProfile: PropTypes.func,
    updateUserData: PropTypes.func,
    cleanEventsData: PropTypes.func,
    logoutUser: PropTypes.func,
  }).isRequired,
};

NavLinks.defaultProps = {
  links: [],
  user: {},
};

