import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';
import Autosuggest from 'react-autosuggest';
import { LocationsService } from 'shared/services';
import TextInput from '../TextInput/TextInput';

const styles = {
  suggestionsContainer: {
    position: 'absolute',
  },
  suggestionsList: {
    position: 'absolute',
    minWidth: 200,
    maxHeight: 300,
    overflowY: 'auto',
    top: 0,
    zIndex: 100,
    padding: '10px 10px',
    listStyleType: 'none',
    backgroundColor: '#fff',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
  },
  suggestion: {
    padding: 5,
    cursor: 'pointer',
  },
  suggestionHighlighted: {
    backgroundColor: '#eee',
  },
};

const getCityInputValue = (data) => {
  const { city, county } = data;
  if (!city || !county) {
    return '';
  }
  if (!city.name && !county.name) {
    return '';
  }
  return city && city.name
    ? `${city.name}, jud. ${county.name}`
    : `Judetul ${county.name}`;
};

const renderSuggestion = (suggestion) => (
  <div>
    {suggestion.name}
  </div>
);

const renderTextInput = (inputProps) => {
  const { inputRef = () => {}, ref, ...rest } = inputProps;

  return (
    <TextInput
      variant="outlined"
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
      }}
      fullWidth
      style={{ flex: 1 }}
      {...rest}
    />
  );
};

const getSuggestionValue = (suggestion) => suggestion.name;

function CitySearch({ selected, onSelect, includeCounty }) {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const inputProps = {
    placeholder: 'Caută oraș',
    value: inputValue,
    onChange: (e, { newValue }) => {
      setInputValue(newValue);
      if (!newValue) {
        // input was cleared, notify parent in case it allows saving empty value
        onSelect({
          name: '',
          data: { city: null, county: null },
        });
      }
    },
    error: !!error,
    helperText: error,
  };
  const onFetch = async (ev) => {
    const { value, reason } = ev;
    // Search only at min 2 chars
    if (reason !== 'input-changed' || value.length < 2) {
      return;
    }
    try {
      const results = await LocationsService.searchCities(value, includeCounty);
      setSuggestions(results.map((result) => {
        let { county, ...city } = result;
        if (!county) {
          county = { ...city };
          city = {};
        }
        return {
          name: getCityInputValue({ city, county }),
          data: { city, county },
        };
      }));
    } catch (e) {
      console.log(e);
      setError('Ceva nu a mers. Te rugam incearca mai tarziu');
    }
  };
  const onClearInput = () => {
    setSuggestions([]);
  };
  const selectSuggestion = (ev, { suggestion }) => {
    onSelect(suggestion);
  };

  useEffect(() => {
    if (isObject(selected)) {
      setInputValue(getCityInputValue(selected));
    } else {
      setInputValue(selected);
    }
  }, [selected]);
  return (
    <Autosuggest
      theme={styles}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onFetch}
      onSuggestionsClearRequested={onClearInput}
      onSuggestionSelected={selectSuggestion}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderTextInput}
      inputProps={inputProps}
    />
  );
}

export default CitySearch;

CitySearch.propTypes = {
  selected: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  onSelect: PropTypes.func.isRequired,
  includeCounty: PropTypes.bool,
};

CitySearch.defaultProps = {
  selected: '',
  includeCounty: false,
};
