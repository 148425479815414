import { createActions, handleActions } from 'redux-actions';
import isEmpty from 'lodash/isEmpty';
import BudgetService from './service';

const actionCreators = createActions({
  BUDGET: {
    REQUEST: () => ({ isLoading: true }),
    ERROR_RESPONSE: data => ({ ...data }),
    GET_BUDGET_RESP: data => ({ ...data }),
    GET_CATEGORIES_RESP: data => ([...data]),
    ADD_EXPENSE_RESP: data => ({ ...data }),
    UPDATE_EXPENSE_RESP: data => ({ ...data }),
    REMOVE_EXPENSE_RESP: data => ({ ...data }),
  },
});
const {
  request,
  errorResponse,
  getBudgetResp,
  getCategoriesResp,
  addExpenseResp,
  updateExpenseResp,
  removeExpenseResp,
} = actionCreators.budget;

export const budgetReducer = handleActions({
  [request](state, action) {
    return { ...state, ...action.payload };
  },
  [errorResponse](state, action) {
    const { data } = action.payload.response;
    return {
      ...state,
      isLoading: false,
      error: data.message || data.errors,
    };
  },
  [getBudgetResp](state, action) {
    return {
      ...state,
      isLoading: false,
      budget: { ...action.payload },
    };
  },
  [getCategoriesResp](state, action) {
    return {
      ...state,
      isLoading: false,
      expenseCategories: action.payload,
    };
  },
  [addExpenseResp](state, action) {
    return {
      ...state,
      isLoading: false,
      budget: {
        ...state.budget,
        expenses: action.payload.expenses,
      },
    };
  },
  [updateExpenseResp](state, action) {
    return {
      ...state,
      isLoading: false,
      budget: {
        ...state.budget,
        expenses: action.payload.expenses,
      },
    };
  },
  [removeExpenseResp](state, action) {
    return {
      ...state,
      isLoading: false,
      budget: {
        ...state.budget,
        expenses: action.payload.expenses,
      },
    };
  },
}, {
  isLoading: false,
  error: null,
  budget: {},
  summary: {},
  expenseCategories: [],
});

export const setUpdatedExpenses = budget => dispatch => dispatch(updateExpenseResp(budget));

export const getBudget = eventId =>
  async function (dispatch, getState) {
    const { budget } = getState().budgetReducer;
    // don't request if we have it
    if (!isEmpty(budget)) {
      return;
    }
    dispatch(request());
    try {
      const resp = await BudgetService.getEventBudget(eventId);
      dispatch(getBudgetResp(resp));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const updateBudget = budget =>
  async function (dispatch) {
    dispatch(request());
    try {
      const newBudget = await BudgetService.updateBudget(budget);
      dispatch(getBudgetResp(newBudget));
    } catch (err) {
      dispatch(errorResponse);
    }
  };

export const getExpenseCategories = eventType =>
  async function (dispatch, getState) {
    const { expenseCategories } = getState().budgetReducer;
    // don't request if we have it
    if (expenseCategories.length) {
      return;
    }
    dispatch(request());
    try {
      const categories = await BudgetService.getExpenseCategories(eventType);
      dispatch(getCategoriesResp(categories));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const updateExpense = data =>
  async function (dispatch) {
    dispatch(request());
    try {
      const newBudget = await BudgetService.updateExpense(data);
      if (!newBudget) {
        dispatch(errorResponse());
        return;
      }
      dispatch(updateExpenseResp(newBudget));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const removeExpense = (budgetId, expenseId) =>
  async function (dispatch) {
    dispatch(request());
    try {
      const newBudget = await BudgetService.removeExpense(budgetId, expenseId);
      if (!newBudget) {
        dispatch(errorResponse());
        return;
      }
      dispatch(removeExpenseResp(newBudget));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const addExpense = data =>
  async function (dispatch) {
    dispatch(request());
    try {
      const newBudget = await BudgetService.addExpense(data);
      dispatch(addExpenseResp(newBudget));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };
