import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import Icon from 'react-icons-kit';
import { facebook } from 'react-icons-kit/fa/facebook';
import { google } from 'react-icons-kit/fa/google';
import FormHelperText from '@material-ui/core/FormHelperText';

import { FB_ID, G_ID } from 'config';
import Card from 'shared/mdr/components/Card/Card';
import CardBody from 'shared/mdr/components/Card/CardBody';
import Button from 'shared/mdr/components/CustomButtons/Button';
import CustomInput from 'shared/mdr/components/CustomInput/CustomInput';

import { cardTitle, dangerColor } from 'shared/material-kit-react/src/assets/jss/material-kit-pro-react';

const styles = {
  cardTitle: {
    ...cardTitle,
    color: '#ffffff',
    fontSize: '1.2rem',
    lineHeight: '1.5em',
    fontWeight: '300',
  },
  textCenter: {
    textAlign: 'center',
  },
  loginSection: {
    maxWidth: 480,
    zIndex: 10,
  },
  cardHeader: {
    backgroundColor: dangerColor[0],
    color: '#fff',
    textAlign: 'center',
    maxWidth: 480,
    padding: 20,
    marginTop: 20,
    fontWeight: 500,
    fontSize: 16,
  },
  loginCard: {
    borderRadius: 0,
    marginTop: 0,
  },
  cardBody: {
    padding: '30px 50px',
  },
  marginTop: {
    marginTop: 15,
  },
  submitBtn: {
    width: '100%',
  },
  notActive: {
    backgroundColor: 'rgba(244,67,54, .05)',
    border: '1px solid rgba(244,67,54, .3)',
    borderRadius: 5,
    marginTop: 10,
    textAlign: 'center',
    padding: 5,
  },
  resetBtn: {
    textTransform: 'none',
    textDecoration: 'underline',
  },
  gButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& span': {
      fontWeight: 400,
      fontSize: 12,
    },
  },
};

const LoginForm = (props) => {
  const {
    classes,
    data,
    onChange,
    onSubmit,
    errors,
    disabled,
  } = props;

  const onGFailure = (response) => console.error(response);

  return (
    <section className={classes.loginSection}>
      <div className={classes.cardHeader}>
        Login
      </div>
      <Card className={classes.loginCard}>
        <CardBody className={classes.cardBody}>
          <form onSubmit={onSubmit} noValidate>
            <CustomInput
              labelText="Email"
              formControlProps={{
                fullWidth: true,
                margin: 'none',
                required: true,
                error: !!errors.email || !!errors.form,
              }}
              inputProps={{
                onChange,
                // autoComplete: 'email',
                name: 'email',
                type: 'email',
                value: data.email,
              }}
            />
            {errors.email
              ? <FormHelperText error>{errors.email}</FormHelperText>
              : null}
            <CustomInput
              labelText="Parola"
              formControlProps={{
                fullWidth: true,
                required: true,
                error: !!errors.password || !!errors.form,
              }}
              inputProps={{
                onChange,
                // autoComplete: 'password',
                name: 'password',
                type: 'password',
                value: data.password,
              }}
            />
            {errors.password
              ? <FormHelperText error>{errors.password}</FormHelperText>
              : null}
            {errors.form
              ? <FormHelperText error>{errors.form}</FormHelperText>
              : null}
            {errors.form && !errors.notVerified
              && (
              <Button className={classes.resetBtn} link onClick={props.onForgotPass}>
                Ai uitat parola? Trimite email pentru resetare!
              </Button>
              )}
            <Button
              fullWidth
              color="danger"
              type="submit"
              className={classes.submitBtn}
              disabled={disabled}
            >
              Login
            </Button>
            {errors.notVerified
              && (
              <div className={classes.notActive}>
                Contul tău nu a fost activat
                <br />
                <Button link color="danger" onClick={props.onResend}>
                  Retrimite emailul de activare
                </Button>
              </div>
              )}
          </form>
          <div className="horizontal-divider">
            <span className="divider-text">sau</span>
          </div>
          <FacebookLogin
            appId={FB_ID}
            autoLoad={false}
            disableMobileRedirect
            fields="name,email,picture"
            callback={props.onFbResponse}
            render={(renderProps) => (
              <Button color="facebook" fullWidth onClick={renderProps.onClick}>
                <Icon size={18} icon={facebook} />
                Login cu Facebook
              </Button>
            )}
          />
          <GoogleLogin
            clientId={G_ID}
            autoLoad={false}
            responseType="code"
            onSuccess={props.onGResponse}
            onFailure={onGFailure}
            cookiePolicy="single_host_origin"
            buttonText="LOGIN CU GOOGLE"
            className={classes.gButton}
          />
          <div className={`${classes.marginTop} ${classes.textCenter}`}>
            <Link to="/auth/register" href="/auth/register" color="danger">
              Nu ai cont? Creează unul aici!
            </Link>
          </div>
        </CardBody>
      </Card>
    </section>
  );
};

LoginForm.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onForgotPass: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  onFbResponse: PropTypes.func.isRequired,
  onGResponse: PropTypes.func.isRequired,
};

export default withStyles(styles)(LoginForm);
