import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import { primaryColor } from 'shared/mdr/assets/jss/material-dashboard-react';

const styles = {
  denseInput: {
    paddingTop: 11,
    paddingBottom: 11,
  },
  smallInput: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  inputLabel: {
    transform: 'translate(14px, 14px) scale(1)!important',
  },
  smallLabel: {
    transform: 'translate(10px, 8px) scale(1)!important',
  },
  shrinkLabel: {
    transform: 'translate(14px, -6px) scale(0.75)!important',
  },
};

const theme = createTheme({
  palette: {
    primary: { main: primaryColor[0] },
  },
});

const TextInput = ({
  classes, InputProps, InputLabelProps, sm, ...rest
}) => (
  <MuiThemeProvider theme={theme}>
    <TextField
      margin="dense"
      InputProps={{
        ...InputProps,
        classes: {
          input: sm ? classes.smallInput : classes.denseInput,
        },
      }}
      InputLabelProps={{
        ...InputLabelProps,
        classes: {
          outlined: sm ? classes.smallLabel : classes.inputLabel,
          shrink: classes.shrinkLabel,
        },
      }}
      {...rest}
    />
  </MuiThemeProvider>
);

export default withStyles(styles)(TextInput);
