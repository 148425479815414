import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import useObservable from 'hooks/useObservable';
import UserStore from 'store/UserStore';
import { isEmpty } from 'lodash/lang';

const styles = {
  container: {
    height: 41,
    minWidth: 41,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    color: '#fff',
    backgroundColor: '#e6766c',
    fontWeight: 500,
    fontSize: 16,
  },
};
const useStyles = makeStyles(styles);
const getInitials = (profile) => {
  if (!profile) {
    return '';
  }
  let initials = '';
  if (profile.firstName) {
    initials += profile.firstName.charAt(0).toUpperCase();
  }
  if (profile.lastName) {
    initials += profile.lastName.charAt(0).toUpperCase();
  }
  if (!initials && profile.username) {
    return profile.username.split(' ').map((n) => n.charAt(0).toUpperCase()).join('');
  }
  return initials;
};

const Avatar = () => {
  const userProfile = useObservable(UserStore.current$, {});
  const [initials, setInitials] = useState(getInitials(userProfile));
  const classes = useStyles();
  useEffect(() => {
    setInitials(getInitials(userProfile));
  }, [userProfile]);

  if (isEmpty(userProfile)) {
    return null;
  }

  return (
    <span className={classes.container}>
      {initials}
    </span>
  );
};

export default Avatar;
