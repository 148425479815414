import React from 'react';
import PropTypes from 'prop-types';

import SweetAlert from 'react-bootstrap-sweetalert';
import withStyles from '@material-ui/core/styles/withStyles';

import buttonStyle from 'shared/mdr/assets/jss/material-dashboard-react/components/buttonStyle';

const GeneralError = ({
  classes,
  title,
  message,
  onHide,
}) => (
  <SweetAlert
    error
    style={{ color: '#3C4858' }}
    title={title}
    onConfirm={onHide}
    onCancel={onHide}
    confirmBtnCssClass={`${classes.button} ${classes.danger}`}
    confirmBtnText="OK"
  >
    {message}
  </SweetAlert>
);

export default withStyles(buttonStyle)(GeneralError);

GeneralError.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  onHide: PropTypes.func.isRequired,
};

GeneralError.defaultProps = {
  title: 'Ooops!',
  message: 'Ceva nu a mers cum ne-am asteptat. Te rugam incearca mai tarziu.',
};
