import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { Icon } from 'react-icons-kit';
import { socialFacebook, socialTwitter } from 'react-icons-kit/metrize';
import { whatsapp } from 'react-icons-kit/fa/whatsapp';

const style = theme => ({
  container: {
    marginTop: 20,
    '& svg': {
      cursor: 'pointer',
    },
  },
  btn: {
    display: 'inline-block',
    marginLeft: 10,
  },
  text: {
    fontWeight: 500,
    //display: 'block',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
      textAlign: 'inherit',
    },
  },
});

const SocialShare = ({ classes, url, size, align }) => {

  const shareTitle = window.document.title;
  const hashtag = 'epicplanro';

  return (
    <div className={classes.container} style={{ textAlign: align }}>
      <div className={`${classes.btn} ${classes.text}`}>
        Share
      </div>
      <FacebookShareButton className={classes.btn} url={url} hashtag={`#${hashtag}`}>
        <Icon size={size} icon={socialFacebook} />
      </FacebookShareButton>
      <TwitterShareButton
        className={classes.btn}
        url={url}
        hashtags={[hashtag]}
        title={shareTitle}
        via="epicplan_ro"
      >
        <Icon size={size} icon={socialTwitter} />
      </TwitterShareButton>
      <WhatsappShareButton
        className={classes.btn}
        url={url}
        title={shareTitle}
      >
        <Icon size={size} icon={whatsapp} />
      </WhatsappShareButton>
    </div>
  );
}

export default withStyles(style)(SocialShare);

SocialShare.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  url: PropTypes.string,
  size: PropTypes.number,
  align: PropTypes.string,
};

SocialShare.defaultProps = {
  url: window.location.href,
  size: 24,
  align: 'left',
}