const primaryColor = "#9c27b0";
const warningColor = "#ff9800";
const dangerColor = "#f44336";
const successColor = "#4caf50";
const infoColor = "#00acc1";
const roseColor = "#e91e63";
const grayColor = "#999999";

const bgPrimary = {
  backgroundColor: primaryColor,
};

const bgWarning = {
  backgroundColor: warningColor,
};

const bgDanger = {
  backgroundColor: dangerColor,
};

const bgSuccess = {
  backgroundColor: successColor,
};

const bgInfo = {
  backgroundColor: infoColor,
};

const bgRose = {
  backgroundColor: roseColor,
};

const bgGray = {
  backgroundColor: grayColor,
};

const colorGray = {
  color: grayColor,
}

export {
  bgWarning,
  bgDanger,
  bgSuccess,
  bgInfo,
  bgRose,
  bgGray,
  colorGray,
  infoColor,
  dangerColor,
}