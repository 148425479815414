import { createTheme } from '@material-ui/core/styles';
import { extendTheme } from '@chakra-ui/react';

const theme = createTheme({
  typography: {
    fontFamily: 'OpenSans, Roboto, Helvetica, Arial, sans-serif',
    fontSize: 14,
    fontWeightRegular: 400,
    body1: {
      fontSize: 14,
    },
  },
  palette: {
    primary: {
      main: '#e6766c',
    },
    warning: {
      main: '#ff9800',
    },
    success: {
      main: '#4caf50',
    },
  },
});

export const chakraTheme = extendTheme({
  fonts: {
    heading: 'Raleway, OpenSans, Helvetica, Arial, sans-serif',
    body: 'OpenSans, Roboto, Helvetica, Arial, sans-serif',
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 600,
        color: '#22292e',
      }
    },
    Button: {
      baseStyle: {
        fontWeight: 400,
      }
    },
    Text: {
      baseStyle: {
        fontWeight: 400,
        color: '#22292e',
      }
    },
  },
  colors: {
    brand:
      {
        50: '#faf8f8',
        100: '#ffebe4',
        200: '#f8c5bc',
        300: '#ed9c91',
        400: '#e6766c',
        500: '#dd543c',
        600: '#c34622',
        700: '#993d1a',
        800: '#6e3112',
        900: '#432108',
      },
  },
});

export default theme;
