import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Icon from 'react-icons-kit';
import { facebookF } from 'react-icons-kit/fa/facebookF';
import { instagram } from 'react-icons-kit/fa/instagram';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'shared/mdr/components/Grid/GridContainer';
import GridItem from 'shared/mdr/components/Grid/GridItem';
import Button from 'shared/mdr/components/CustomButtons/Button';

import styles from './AppFooterStyle';

const AppFooter = ({
  classes, color, showPromote, ...rest
}) => (
  <footer className={`${classes.footer} ${color}`} {...rest}>
    <GridContainer justifyContent="space-between" alignItems="center">
      <GridItem xs={7} sm={6}>
        {showPromote
          && (
          <Button
            outlined
            href="https://epicplan.ro/pentru-furnizori"
            color="danger"
            size="sm"
            style={{ textTransform: 'none' }}
          >
            Promovează-ți afacerea gratuit
          </Button>
          )}
        <Button
          justIcon
          simple
          href="https://facebook.com/epicplan.ro"
          target="_blank"
          style={{ color: '#999' }}
        >
          <Icon size={32} icon={facebookF} />
        </Button>
        <Button
          justIcon
          simple
          href="https://instagram.com/epicplan.ro"
          target="_blank"
          style={{ color: '#999' }}
        >
          <Icon size={24} icon={instagram} />
        </Button>
        <a
          to="https://epicplan.ro/contact"
          href="https://epicplan.ro/contact"
          target="_blank"
          className={classes.contactLink}
          rel="noreferrer"
        >
          Contact
        </a>
      </GridItem>
      <GridItem xs={5} sm={6} style={{ textAlign: 'right' }}>
        <a
          className={classes.termsLink}
          to="https://epicplan.ro/termeni-si-conditii/"
          href="https://epicplan.ro/termeni-si-conditii/"
          target="_blank"
          rel="noreferrer"
        >
          Termeni si conditii
        </a>
        <a
          className={classes.termsLink}
          to="https://epicplan.ro/politica-de-cookies"
          href="https://epicplan.ro/politica-de-cookies"
          target="_blank"
          rel="noreferrer"
        >
          Politica de cookies
        </a>
        <a
          className={classes.termsLink}
          to="https://epicplan.ro/protectia-datelor-cu-caracter-personal/"
          href="https://epicplan.ro/protectia-datelor-cu-caracter-personal/"
          target="_blank"
          rel="noreferrer"
        >
          Politica de prelucrare a datelor
        </a>
      </GridItem>
    </GridContainer>
  </footer>
);

export default withStyles(styles)(AppFooter);

AppFooter.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  color: PropTypes.string,
  showPromote: PropTypes.bool,
};

AppFooter.defaultProps = {
  color: '',
  showPromote: false,
};
