import React from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/mdr/components/CustomButtons/Button';
import { ReactComponent as GoogleIcon } from 'assets/img/google_light.svg';
import SvgIcon from '@material-ui/core/SvgIcon';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  btn: {
    backgroundColor: 'rgba(64,87,109,.07)',
    color: '#22292e',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '7px 10px',
    marginBottom: 14,
    '& svg': {
      height: 24,
      width: 24,
    },
  },
  label: {
    flex: 1,
    textTransform: 'none',
    fontSize: 14,
  },
};
function GoogleButton({ classes, onClick, text }) {
  return (
    <Button fullWidth onClick={onClick} className={classes.btn}>
      <SvgIcon>
        <GoogleIcon />
      </SvgIcon>
      <span className={classes.label}>{text}</span>
    </Button>
  );
}

export default withStyles(styles)(GoogleButton);

GoogleButton.propTypes = {
  classes: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};
