import { createActions, handleActions } from 'redux-actions';
import keyBy from 'lodash/keyBy';
import pickBy from 'lodash/pickBy';
import GuestService from './guestService';

// ACTION CREATORS
const {
  request,
  errorResponse,
  createResponse,
  getGuestsResp,
  updateGuestResp,
  deleteGuestResp,
} = createActions({
  REQUEST: () => ({ isLoading: true }),
  ERROR_RESPONSE: data => ({ ...data }),
  CREATE_RESPONSE: data => ({ ...data }),
  GET_GUESTS_RESP: data => ({ ...keyBy(data, '_id') }),
  UPDATE_GUEST_RESP: data => ({ ...data }),
  DELETE_GUEST_RESP: data => data,
});

export const guestReducer = handleActions({
  [request](state, action) {
    return { ...state, ...action.payload };
  },
  [errorResponse](state, action) {
    return {
      ...state,
      isLoading: false,
      error: action.payload.response.data.message || action.payload.response.data.errors,
    };
  },
  [createResponse](state, action) {
    return {
      ...state,
      isLoading: false,
      currentGuest: action.payload,
      guests: {
        ...state.guests,
        [action.payload._id]: action.payload,
      },
    };
  },
  [getGuestsResp](state, action) {
    return {
      ...state,
      isLoading: false,
      guests: action.payload,
    };
  },
  [updateGuestResp](state, action) {
    return {
      ...state,
      isLoading: false,
      guests: {
        ...state.guests,
        [action.payload._id]: action.payload,
      },
      currentGuest: action.payload,
    };
  },
  [deleteGuestResp](state, action) {
    const guests = pickBy(state.guests, g => g._id !== action.payload);
    return {
      ...state,
      isLoading: false,
      guests,
      currentGuest: {},
    };
  },
}, {
  isLoading: false,
  currentGuest: {},
  error: null,
  guests: {},
});

export const setAddedGuest = guest => dispatch => dispatch(createResponse(guest));
export const setUpdatedGuest = guest => dispatch => dispatch(updateGuestResp(guest));
export const setRemovedGuest = guest => dispatch => dispatch(deleteGuestResp(guest));

export const createGuest = data =>
  async function (dispatch) {
    dispatch(request());
    try {
      const newGuest = await GuestService.createGuest(data);
      dispatch(createResponse(newGuest));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const getGuests = eventId =>
  async function (dispatch) {
    dispatch(request());
    try {
      const resp = await GuestService.getEventGuests(eventId);
      dispatch(getGuestsResp(resp));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const updateGuest = guest =>
  async function (dispatch) {
    dispatch(request());
    try {
      const newGuest = await GuestService.updateGuest(guest);
      dispatch(updateGuestResp(newGuest));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const deleteGuest = guest =>
  async function (dispatch) {
    dispatch(request());
    try {
      await GuestService.deleteGuest(guest._id);
      dispatch(deleteGuestResp(guest._id));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };
