/* global localStorage */

const LocalStorage = {
  set(name, data) {
    localStorage.setItem(name, JSON.stringify(data));
  },
  get(name) {
    try {
      return JSON.parse(localStorage.getItem(name)) || null;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  remove(name) {
    return localStorage.removeItem(name);
  },
  clear() {
    localStorage.clear();
  },
};

export default LocalStorage;