import { createActions, handleActions } from 'redux-actions';
import { decode } from 'ent';

import EventService from 'shared/services/EventService';

const initialState = {
  isLoading: false,
  eventsLoading: false,
  newEvent: {},
  events: [],
  eventTypes: [],
  currentEvent: {},
  summary: {},
  error: null,
};

// ACTION CREATORS
const actionCreators = createActions({
  EVENT: {
    ERROR_RESPONSE: (data) => data,
    CREATE_REQUEST: () => ({ isLoading: true }),
    CREATE_RESPONSE: (data) => ({ ...data }),
    EVENTS_REQUEST: () => ({ eventsLoading: true }),
    EVENTS_RESPONSE: (data) => ([...data]),
    SET_CURRENT: (event) => ({ currentEvent: { ...event } }),
    UPDATE_EVENT_RESPONSE: (event) => ({ ...event }),
    UPDATE_TASK: (data) => ({ ...data }),
    ADD_TASK: (data) => ({ ...data }),
    DELETE_TASK: (data) => ({ ...data }),
    GET_EVENT_RESPONSE: (data) => ({ ...data }),
    GET_TASKS_RESPONSE: (data) => ([...data]),
    GET_SUMMARY_RESPONSE: (data, eventId) => ({ ...data, eventId }),
    GET_EVENT_TYPES_RESP: (data) => ([...data]),
    CLEAN_EVENTS: (data) => data,
    DELETE_EVENT: (data) => data,
  },
});
const {
  createRequest,
  createResponse,
  eventsRequest,
  eventsResponse,
  updateEventResponse,
  setCurrent,
  updateTask,
  addTask,
  deleteTask,
  cleanEvents,
  getEventResponse,
  getTasksResponse,
  getSummaryResponse,
  getEventTypesResp,
  errorResponse,
  deleteEvent,
  removeTeamEvent,
} = actionCreators.event;

const updateEvents = (events, newEvent) => events.map((ev) => {
  if (ev._id === newEvent._id) {
    return newEvent;
  }
  return ev;
});

const updateEventTasks = (state, action) => {
  if (action.error) {
    return {
      ...state,
      error: action.error,
      errorMessage: action.payload.response.data.message,
    };
  }
  const currentEvent = {
    ...state.currentEvent,
    tasks: action.payload.map((t) => ({
      ...t,
      description: decode(t.description || ''),
    })),
  };
  return {
    ...state,
    currentEvent,
    events: updateEvents(state.events, currentEvent),
  };
};

export const eventReducer = handleActions({
  [createRequest](state, action) {
    return { ...state, ...action.payload };
  },
  [errorResponse](state, action) {
    const { data, status } = action.payload.response;
    const error = data.message || data.errors;
    return {
      ...state,
      isLoading: false,
      error,
      errorCode: status,
    };
  },
  [createResponse](state, action) {
    const newEvent = {
      ...action.payload,
      name: decode(action.payload.name || ''),
    };
    return {
      ...state,
      isLoading: false,
      events: [...state.events.concat(newEvent)],
      error: null,
    };
  },
  [updateEventResponse](state, action) {
    const currentEvent = {
      ...action.payload,
      name: decode(action.payload.name || ''),
    };
    const events = state.events.map((ev) => {
      if (ev._id === action.payload._id) {
        return currentEvent;
      }
      return ev;
    });
    return {
      ...state,
      events,
      isLoading: false,
      error: null,
      currentEvent,
    };
  },
  [eventsRequest](state, action) {
    return { ...state, ...action.payload };
  },
  [eventsResponse](state, action) {
    const { response } = action.payload;
    return {
      ...state,
      eventsLoading: false,
      error: action.error,
      errorMessage: action.error
        ? response && response.data && response.data.message
        : null,
      events: !action.error
        ? action.payload.map((ev) => ({
          ...ev,
          name: decode(ev.name || ''),
        }))
        : [],
    };
  },
  [setCurrent](state, action) {
    return {
      ...state,
      ...action.payload,
      events: updateEvents(state.events, action.payload),
    };
  },
  [updateTask](state, action) {
    return {
      ...state,
      currentEvent: {
        ...state.currentEvent,
        tasks: state.currentEvent?.tasks?.map((t) => {
          if (t._id === action.payload._id) {
            return { ...action.payload };
          }
          return { ...t };
        }),
      },
    };
  },
  [addTask](state, action) {
    return {
      ...state,
      currentEvent: {
        ...state.currentEvent,
        tasks: state.currentEvent.tasks.concat([action.payload]),
      },
    };
  },
  [deleteTask](state, action) {
    return {
      ...state,
      currentEvent: {
        ...state.currentEvent,
        tasks: state.currentEvent.tasks.filter((t) => t._id !== action.payload._id),
      },
    };
  },
  [getEventResponse](state, action) {
    return {
      ...state,
      currentEvent: action.error ? {} : {
        ...action.payload,
        name: decode(action.payload.name || ''),
      },
      events: state.events.map((ev) => {
        if (ev._id === action.payload._id) {
          return {
            ...action.payload,
            name: decode(action.payload.name || ''),
          };
        }
        return ev;
      }),
    };
  },
  [getTasksResponse](state, action) {
    return updateEventTasks(state, action);
  },
  [getSummaryResponse](state, action) {
    return {
      ...state,
      summary: {
        [action.payload.eventId]: {
          ...action.payload,
        },
      },
    };
  },
  [getEventTypesResp](state, action) {
    return {
      ...state,
      isLoading: false,
      eventTypes: action.payload,
    };
  },
  [cleanEvents]() {
    return {
      ...initialState,
    };
  },
  [deleteEvent](state, action) {
    const { events } = state;
    const newEvents = events.filter((ev) => ev._id !== action.payload);
    return {
      events: [...newEvents],
      currentEvent: newEvents[0] || {},
      summary: {
        ...state.summary,
        [action.payload]: undefined,
      },
    };
  },
  [removeTeamEvent](state, action) {
    const { eventId } = action.payload;
    return {
      events: state.events.filter((ev) => ev._id !== eventId),
    };
  },
}, initialState);

export const setCurrentEvent = (event) => (dispatch) => dispatch(setCurrent(event));
export const updateEventTask = (task) => (dispatch) => dispatch(updateTask(task));
export const addEventTask = (task) => (dispatch) => dispatch(addTask(task));
export const deleteEventTask = (task) => (dispatch) => dispatch(deleteTask(task));
export const cleanEventsData = () => (dispatch) => dispatch(cleanEvents());
export const setEventData = (data) => (dispatch) => dispatch(updateEventResponse(data));

export const getUserEvents = (forceUpdate) => async function (dispatch, getState) {
  if (!forceUpdate && getState().userReducer.events.length) {
    dispatch(eventsResponse(getState().userReducer.events));
    return;
  }
  dispatch(eventsRequest());
  try {
    const events = await EventService.getUserEvents();
    dispatch(eventsResponse(events));
  } catch (err) {
    dispatch(eventsResponse(err));
  }
};

export const createEvent = (data) => async function (dispatch) {
  dispatch(createRequest());
  try {
    const newEvent = await EventService.createEvent(data);
    dispatch(createResponse(newEvent));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const updateEvent = (data) => async function (dispatch) {
  dispatch(createRequest());
  try {
    const newEvent = await EventService.updateEvent(data);
    dispatch(updateEventResponse(newEvent));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const getEvent = (eventId) => async function (dispatch) {
  dispatch(createRequest());
  try {
    const event = await EventService.getEvent(eventId);
    dispatch(getEventResponse(event));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const getEventTasks = (eventId) => async function (dispatch) {
  dispatch(createRequest());
  try {
    const tasks = await EventService.getEventTasks(eventId);
    dispatch(getTasksResponse(tasks));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const getEventSummary = (eventId) => async function (dispatch) {
  dispatch(createRequest());
  try {
    const summary = await EventService.getEventSummary(eventId);
    dispatch(getSummaryResponse(summary, eventId));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const getEventTypes = () => async function (dispatch) {
  // dispatch(createRequest());
  try {
    const eventTypes = await EventService.getEventTypes();
    dispatch(getEventTypesResp(eventTypes));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const updateTeam = (toAdd, toRemove, eventId, owner) => async function (dispatch) {
  try {
    const newEvent = await EventService.updateTeam(toAdd, toRemove, eventId, owner);
    dispatch(updateEventResponse(newEvent));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const removeEvent = (eventId) => async function (dispatch) {
  try {
    await EventService.deleteEvent(eventId);
    dispatch(deleteEvent(eventId));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const exitTeam = (eventId, userId) => async function (dispatch) {
  try {
    await EventService.exitTeam(eventId, userId);
    dispatch(deleteEvent(eventId));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};
