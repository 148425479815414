import React, { Component } from 'react';
import PropTypes from 'prop-types';
import he from 'he';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Edit from '@material-ui/icons/EditOutlined';
import Delete from '@material-ui/icons/DeleteOutlined';
import AddCircle from '@material-ui/icons/AddCircleOutline';

import Card from 'shared/mdr/components/Card/Card';
import CardIcon from 'shared/mdr/components/Card/CardIcon';
import CardHeader from 'shared/mdr/components/Card/CardHeader';
import CardBody from 'shared/mdr/components/Card/CardBody';
import CardFooter from 'shared/mdr/components/Card/CardFooter';
import Button from 'shared/mdr/components/CustomButtons/Button';
import Badge from 'shared/mdr/components/Badge/Badge';

import ProductCardStyles from './ProductCardStyles';

const ProductDetails = ({
  product,
  addNew,
  classes,
  onEdit,
  onDelete,
}) => (
  <Card product>
    {!addNew &&
      <CardHeader image>
        <img
          className={classes.cardImgTop}
          src={product.images[0] || '/assets/img/champagne.jpg'}
          alt={product.name}
        />
      </CardHeader>
    }
    <CardBody>
      <h4 className={classes.cardProductTitle}>
        {he.decode(product.name || '')}
      </h4>
      <p className={classes.cardProductDescription}>
        {he.decode(product.description || '')}
      </p>
      {product.category && <Badge color="info">{product.category.name}</Badge>}
    </CardBody>
    {!addNew &&
    <CardFooter product>
      <div className={classes.price}>
        <h4>{product.price} RON</h4>
      </div>
      <div className={classes.productStats}>
        {onEdit &&
        <Tooltip
          key="edit"
          title="Modifica"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button justIcon simple color="success" onClick={onEdit}>
            <Edit />
          </Button>
        </Tooltip>}
        {onDelete &&
        <Tooltip
          key="delete"
          title="Sterge"
          placement="top"
          classes={{ tooltip: classes.tooltip }}
        >
          <Button justIcon simple color="danger" onClick={onDelete}>
            <Delete />
          </Button>
        </Tooltip>}
      </div>
    </CardFooter>}
    {addNew &&
    <CardFooter product className={classes.textCenter}>
      <Button
        color="danger"
        outlined
        href="/supplier/add-product"
      >
        <AddCircle />
        Adauga
      </Button>
    </CardFooter>}
  </Card>
);

ProductDetails.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  product: PropTypes.shape({}).isRequired,
};

export default withStyles(ProductCardStyles)(ProductDetails);
