import { createActions, handleActions, combineActions } from 'redux-actions';
import he from 'he';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import ProductService from 'shared/services/ProductService';
import SupplierService from './SupplierService';
import EventLogService from 'shared/services/EventLogService';

// ACTION CREATORS
const actionCreators = createActions({
  SUPPLIER: {
    REQUEST: () => ({ isLoading: true }),
    ERROR_RESPONSE: data => ({ ...data }),
    GET_SUPPLIER_RESP: data => ({ ...data }),
    GET_PRODUCTS_RESP: data => ({ ...keyBy(data, '_id') }),
    ADD_PRODUCT_RESP: data => ({ ...data }),
    GET_CATEGORIES_RESP: data => data,
    SET_CURRENT: data => ({ ...data }),
    GET_PRODUCT_RESP: data => ({ ...data }),
    DELETE_PRODUCT_RESP: prodId => ({ id: prodId }),
    UPDATE_PRODUCT_RESP: product => ({ ...product }),
    UPDATE_SUPPLIER_RESP: data => ({ ...data }),
    REMOVE_IMAGE_RESP: data => ({ ...data }),
    GET_STATISTICS_RESP: data => ({ ...data }),
  },
});
const {
  request,
  errorResponse,
  getSupplierResp,
  getProductsResp,
  addProductResp,
  getCategoriesResp,
  setCurrent,
  getProductResp,
  deleteProductResp,
  updateProductResp,
  updateSupplierResp,
  removeImageResp,
  getStatisticsResp,
} = actionCreators.supplier;

export const supplierReducer = handleActions({
  [request](state, action) {
    return {
      ...state,
      ...action.payload,
    };
  },
  [errorResponse](state, action) {
    console.log('error response', action);
    const { data } = action.payload.response;
    let error = data.message || data.errors;
    if (!isEmpty(error)) {
      error = 'Ceva nu a functionat. Te rugam sa incerci mai tarziu';
    }
    return {
      ...state,
      isLoading: false,
      error,
    };
  },
  [getSupplierResp](state, action) {
    return {
      ...state,
      error: null,
      isLoading: false,
      supplier: {
        ...action.payload,
        description: he.decode(action.payload.description || ''),
        name: he.decode(action.payload.name || ''),
      },
    };
  },
  [getProductsResp](state, action) {
    const products = {};
    Object.values(action.payload).forEach((prod) => {
      products[prod._id] = {
        ...prod,
        name: he.decode(prod.name || ''),
        description: he.decode(prod.description || ''),
      };
    });
    return {
      ...state,
      isLoading: false,
      error: null,
      products,
      productList: values(products),
    };
  },
  [addProductResp](state, action) {
    const currentProduct = {
      ...action.payload,
      name: he.decode(action.payload.name || ''),
      description: he.decode(action.payload.description || ''),
    };
    const products = {
      ...state.products,
      [action.payload._id]: currentProduct,
    };
    return {
      ...state,
      isLoading: false,
      error: null,
      currentProduct,
      products,
      productList: values(products),
    };
  },
  [getCategoriesResp](state, action) {
    return {
      ...state,
      isLoading: false,
      error: null,
      productCategories: orderBy([...action.payload], ['order']),
    };
  },
  [setCurrent](state, action) {
    return {
      ...state,
      isLoading: false,
      error: null,
      currentProduct: action.payload,
    };
  },
  [getProductResp](state, action) {
    const products = {
      ...state.products,
      [action.payload._id]: {
        ...action.payload,
        name: he.decode(action.payload.name || ''),
        description: he.decode(action.payload.description || ''),
      },
    };
    return {
      ...state,
      isLoading: false,
      error: null,
      currentProduct: action.payload,
      products,
      productList: values(products),
    };
  },
  [deleteProductResp](state, action) {
    const products = omit(state.products, action.payload.id);
    return {
      ...state,
      isLoading: false,
      error: null,
      products,
      productList: values(products),
    };
  },
  [updateProductResp](state, action) {
    const products = {
      ...state.products,
      [action.payload._id]: action.payload,
    };
    return {
      ...state,
      isLoading: false,
      error: null,
      currentProduct: action.payload,
      products,
      productList: values(products),
    };
  },
  [updateSupplierResp](state, action) {
    return {
      ...state,
      isLoading: false,
      error: null,
      supplier: {
        ...action.payload,
        description: he.decode(action.payload.description || ''),
        name: he.decode(action.payload.name || ''),
      },
    };
  },
  [removeImageResp](state, action) {
    return {
      ...state,
      isLoading: false,
      error: null,
      supplier: { ...action.payload },
    };
  },
  [getStatisticsResp](state, action) {
    return {
      ...state,
      isLoading: false,
      error: null,
      stats: action.payload,
    };
  },
}, {
  isLoading: false,
  error: null,
  currentProduct: {},
  products: {},
  productList: [],
  productCategories: [],
  supplier: {},
  stats: {
    days: undefined,
    results: [],
  },
});

export const setCurrentProduct = product => dispatch => dispatch(setCurrent(product))

export const getSupplier = suppId =>
  async function (dispatch) {
    dispatch(request());
    try {
      const supplier = await SupplierService.getById(suppId);
      dispatch(getSupplierResp(supplier));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const getProducts = suppId =>
  async function (dispatch, getState) {
    if (!suppId) {
      suppId = getState().supplierReducer.supplier._id;
    }
    dispatch(request());
    try {
      const products = await SupplierService.getProducts(suppId);
      dispatch(getProductsResp(products));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const getProduct = productId =>
  async function (dispatch, getState) {
    const { products } = getState().supplierReducer;
    if (products[productId]) {
      dispatch(getProductResp(products[productId]));
      return;
    }
    dispatch(request());
    try {
      const product = await ProductService.getProduct(productId);
      dispatch(getProductResp(product));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const addProduct = data =>
  async function (dispatch) {
    dispatch(request);
    try {
      const product = await ProductService.addProduct(data);
      dispatch(addProductResp(product));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const deleteProduct = productId =>
  async function (dispatch) {
    dispatch(request());
    try {
      const deleted = await ProductService.deleteProduct(productId);
      dispatch(deleteProductResp(productId));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  }

export const updateProduct = product =>
  async function (dispatch) {
    dispatch(request());
    try {
      const updatedProduct = await ProductService.updateProduct(product);
      dispatch(updateProductResp(updatedProduct));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const removeProductImage = (imageId, productId) =>
  async function(dispatch) {
    dispatch(request());
    try {
      const product = await ProductService.removeImage(imageId, productId);
      dispatch(updateProductResp(product));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  }

export const getProductCategories = () =>
  async function (dispatch, getState) {
    const { productCategories } = getState().supplierReducer;
    if (productCategories.length) {
      dispatch(getCategoriesResp(productCategories));
      return;
    }
    dispatch(request());
    try {
      const categories = await ProductService.getProductCategories();
      dispatch(getCategoriesResp(categories));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const updateSupplier = data =>
  async function (dispatch) {
    dispatch(request());
    try {
      const supplier = await SupplierService.updateSupplier(data);
      dispatch(updateSupplierResp(supplier));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const removeImage = (imageId, supplierId) =>
  async function (dispatch) {
    dispatch(request());
    try {
      const supplier = await SupplierService.removeImage(imageId, supplierId);
      dispatch(removeImageResp(supplier));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };

export const getStatistics = (supplierId, days) =>
  async function (dispatch) {
    dispatch(request());
    try {
      const stats = await EventLogService.getSupplierLogs(supplierId, days);
      dispatch(getStatisticsResp(stats));
    } catch (err) {
      dispatch(errorResponse(err));
    }
  };
