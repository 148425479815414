import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useMediaQuery, useTheme } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Close from '@material-ui/icons/Close';

import Button from 'shared/mdr/components/CustomButtons/Button';

import { makeStyles } from '@material-ui/core/styles';
import styles from './ModalStyle';

const useStyles = makeStyles(styles);

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" mountOnEnter unmountOnExit {...props} ref={ref} />
));

const Modal = ({
  isOpen,
  maxWidth,
  onClose,
  onSave,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const cancelButton = (
    <Button onClick={onClose} color="transparent">
      Renunță
    </Button>
  );
  const saveButton = (
    <Button onClick={onSave} color="danger">
      {rest.saveText || 'Salvează'}
    </Button>
  );
  const footerClasses = cx({
    [classes.modalFooter]: true,
    [classes.modalFooterCenter]: !rest.stackedActions,
    [classes.stacked]: rest.stackedActions,
  });
  return (
    <Dialog
      open={isOpen}
      fullWidth
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      classes={{
        root: classes.center,
        paper: `${classes.fullModal}`,
      }}
      PaperProps={{
        style: {
          overflow: 'visible',
        },
      }}
      scroll="paper"
      TransitionComponent={Transition}
      onClose={onClose}
      aria-labelledby={rest.title}
    >
      <DialogTitle className={classes.modalHeader} onClose={onClose}>
        <span className={classes.modalTitle}>
          {rest.title}
        </span>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent className={classes.modalBody}>
        {rest.children}
      </DialogContent>
      <DialogActions
        className={footerClasses}
      >
        {rest.actions ? rest.actions : null}
        {rest.showCancel && cancelButton}
        {onSave && saveButton}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  maxWidth: 'md',
};
