/* global sessionStorage */

const SessionStorage = {
  set(name, data) {
    sessionStorage.setItem(name, JSON.stringify(data));
  },
  get(name) {
    return JSON.parse(sessionStorage.getItem(name)) || null;
  },
  remove(name) {
    return sessionStorage.removeItem(name);
  },
  clear() {
    sessionStorage.clear();
  },
};

export default SessionStorage;