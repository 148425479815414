import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { userReducer } from 'shared/ducks/userDuck';
import { eventReducer } from 'shared/ducks/eventDuck';
import { taskReducer } from 'features/tasklist/taskDuck';
import { guestReducer } from 'features/guests/guestDuck';
import { supplierReducer } from 'features/supplier/supplierDuck';
import { budgetReducer } from 'features/budget/duck';
import { invitationReducer } from 'features/invitation/duck';
import { searchReducer } from 'features/search/duck';
import appReducer from './reducers/app';

const store = createStore(
  combineReducers({
    appReducer,
    userReducer,
    eventReducer,
    taskReducer,
    guestReducer,
    supplierReducer,
    budgetReducer,
    invitationReducer,
    searchReducer,
  }),
  applyMiddleware(thunk),
);

export default store;
