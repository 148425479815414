import http from 'shared/services/http';

export default class BudgetService {
  static getEventBudget(eventId) {
    return http.get(`/budget/${eventId}`);
  }
  static updateBudget(budget) {
    return http.put('/budget', budget);
  }
  static deleteBudget(budgetId) {
    return http.delete(`/budget/${budgetId}`);
  }
  static getExpenseCategories(eventType) {
    return http.get(`/budget/categories/${eventType}`);
  }
  static addExpense(data) {
    const { budgetId, ...rest } = data;
    return http.post(`/budget/${budgetId}/expense`, rest);
  }
  static updateExpense(data) {
    const { budgetId, ...rest } = data;
    return http.put(`/budget/${budgetId}/expense`, rest);
  }
  static removeExpense(budgetId, expenseId) {
    return http.delete(`/budget/${budgetId}/expense/${expenseId}`);
  }
}