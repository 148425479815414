import http from 'shared/services/http';

export default class TaskService {
  static createTask(data) {
    return http.post('/task', data);
  }
  static getTask(taskId) {
    return http.get(`/task/${taskId}`);
  }
  static updateTask(data) {
    return http.put('/task', data);
  }
  static deleteTask(taskId) {
    return http.delete(`/task/${taskId}`);
  }
}