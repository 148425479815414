import queryString from 'query-string';
import http from './http';

export default class StaticService {
  static addTags(tags) {
    return http.post('/tags', { tags });
  }
  static getEstimations(type, guests, location, name, email) {
    let query = queryString.stringify({
      guests,
      location,
      name,
      email,
    });
    if (query) {
      query = `?${query}`;
    }
    return http.get(`/estimations/${type}${query}`);
  }
  static getCurrencyRates() {
    return http.get('/currency-rates');
  }
}
