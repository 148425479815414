/* global window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'shared/material-kit-react/src/components/CustomButtons/Button';

import styles from './styles';

class CookieWarn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agreed: !!window.localStorage.getItem('agreedCookies'),
    };
  }

  componentDidMount() {
    const { history } = this.props;
    const listener = history.listen(() => {
      this.setAgreement();
      listener();
    });
  }

  setAgreement = () => {
    window.localStorage.setItem('agreedCookies', 'true');
    this.setState({
      agreed: true,
    });
  };

  listener = () => {};

  render() {
    const { classes } = this.props;
    const { agreed } = this.state;

    if (agreed) {
      return null;
    }
    return (
      <section className={classes.warnContainer}>
        <div className={classes.text}>
          Folosim cookies pentru a personaliza continutul aplicației, integrare cu social media și analiza traficului pe site.
          Prin continuarea navigării vă exprimați acordul cu politica noastră de cookies.
          <Link
            className={classes.detailsLink}
            to="https://epicplan.ro/politica-de-cookies"
            href="https://epicplan.ro/politica-de-cookies"
            target="_blank"
          >
            Mai multe detalii
          </Link>
        </div>
        <Button
          color="success"
          size="sm"
          className={classes.agreeBtn}
          onClick={this.setAgreement}
        >
          Sunt de acord
        </Button>
      </section>
    );
  }
}

export default withStyles(styles)(withRouter(CookieWarn));

CookieWarn.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};
