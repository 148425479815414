import React from 'react';
import { AppLoader } from 'shared/components';

const Loading = (props) => {
  if (props.error) {
    console.error(props.error);
    return <div>Error! <button onClick={() => window.location.reload()}>Reload</button></div>;
  } else if (props.pastDelay) {
    return <AppLoader />;
  }
  return null;
};

export default Loading;