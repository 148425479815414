import modalStyle from 'shared/mdr/assets/jss/material-dashboard-react/modalStyle';

const styles = (theme) => ({
  ...modalStyle(theme),
  modalBody: {
    // overflowY: 'visible',
    paddingTop: 24,
  },
  stacked: {
    flexDirection: 'column',
    '& button': {
      width: '100%',
    },
    '& a': {
      width: '100%',
    },
  },
});

export default styles;
