import { grayColor, dangerColor } from 'shared/mdr/assets/jss/material-dashboard-react';

const styles = theme => ({
  footer: {
    padding: '5px 20px',
    display: 'flex',
    width: '100%',
    bottom: 0,
    position: 'relative',
    '&:before,&:after': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
    '& svg': {
      height: 28,
      width: 28,
    },
    '&.white': {
      backgroundColor: '#fff',
      color: grayColor[0],
    },
  },
  termsLink: {
    fontSize: 12,
    lineHeight: 1.5,
    color: grayColor[0],
    fontWeight: 400,
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      marginRight: 20,
      display: 'inline-block',
    },
  },
  brand: {
    textAlign: 'right',
    color: dangerColor[1],
    '& img': {
      marginRight: 10,
    },
  },
  contactLink: {
    fontWeight: 500,
    padding: '0 15px',
    color: grayColor[0],
  },
});

export default styles;
