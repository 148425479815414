import http from 'shared/services/http';

export default class InvitationService {
  static createInvitation(data) {
    return http.post('/invitation', data);
  }
  static getEventInvitation(eventId) {
    return http.get(`/invitation/event/${eventId}`);
  }
  static updateInvitation(data) {
    return http.put('/invitation', data);
  }
  static sendInvite(data) {
    return http.post('/invitation/send', data);
  }
  static getInvitation(inviteId) {
    return http.get(`/invitation/${inviteId}`);
  }
};
