import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Button from 'shared/mdr/components/CustomButtons/Button';
import { facebookSquare } from 'react-icons-kit/fa/facebookSquare';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  btn: {
    backgroundColor: 'rgba(64,87,109,.07)',
    color: '#22292e',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '7px 10px',
    marginBottom: 14,
    '& svg': {
      height: 24,
      width: 24,
    },
  },
  label: {
    flex: 1,
    textTransform: 'none',
    fontSize: 14,
  },
};

function FacebookButton({
  classes,
  iconColor,
  text,
  onClick,
}) {
  return (
    <Button fullWidth onClick={onClick} className={classes.btn}>
      <Icon icon={facebookSquare} style={{ color: iconColor }} />
      <span className={classes.label}>{text}</span>
    </Button>
  );
}

export default withStyles(styles)(FacebookButton);

FacebookButton.propTypes = {
  classes: PropTypes.shape().isRequired,
  iconColor: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

FacebookButton.defaultProps = {
  iconColor: '#3b5998',
};

