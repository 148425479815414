import { io } from 'socket.io-client';
import store from 'store/store';
import * as EventActions from 'shared/ducks/eventDuck';
import * as TaskActions from 'features/tasklist/taskDuck';
import * as BudgetActions from 'features/budget/duck';
import * as GuestActions from 'features/guests/guestDuck';
import SocketMessage from 'shared/services/SocketMessage';

const BASE_URL = (window.location.protocol === 'http:' ? 'ws://' : 'wss://') + window.location.host;
const BASE_PATH = '/ws';

class SocketManager {
  constructor(roomId, userId) {
    this.room = roomId;
    this.user = userId;
    this.socket = io(`${BASE_URL}/event`, {
      path: BASE_PATH,
      withCredentials: true,
      transports: ['websocket'],
    });
    this.socket.on('connect', () => {
      this.isConnected = true;
      this.socket.emit('join-room', { roomId, userId });
    });
    this.socket.on('joined-room', (data) => {
      this.attachEvents();
    });
  }

  socketEvents = [{
    name: 'event-update',
    method: (data) => {
      store.dispatch(EventActions.setEventData(data.data));
    },
  }, {
    // TASK EVENTS
    name: 'task-update',
    method: (data) => {
      store.dispatch(TaskActions.setTaskData(data.data));
      store.dispatch(EventActions.updateEventTask(data.data));
    },
  }, {
    name: 'task-add',
    method: (data) => {
      store.dispatch(TaskActions.addTask(data.data));
      store.dispatch(EventActions.addEventTask(data.data));
    },
  }, {
    name: 'task-remove',
    method: (data) => {
      store.dispatch(TaskActions.removeTask(data.data));
      store.dispatch(EventActions.deleteEventTask(data.data));
    },
  }, {
    // BUDGET EVENTS
    name: 'expense-add',
    method: (data) => {
      store.dispatch(BudgetActions.setUpdatedExpenses(data.data));
    },
  }, {
    name: 'expense-remove',
    method: (data) => {
      store.dispatch(BudgetActions.setUpdatedExpenses(data.data));
    },
  }, {
    name: 'expense-update',
    method: (data) => {
      store.dispatch(BudgetActions.setUpdatedExpenses(data.data));
    },
  }, {
    // GUEST EVENTS
    name: 'guest-add',
    method: (data) => {
      store.dispatch(GuestActions.setAddedGuest(data.data));
    },
  }, {
    name: 'guest-update',
    method: (data) => {
      store.dispatch(GuestActions.setUpdatedGuest(data.data));
    },
  }, {
    name: 'guest-remove',
    method: (data) => {
      store.dispatch(GuestActions.setRemovedGuest(data.data._id));
    },
  }];

  attachEvents() {
    this.socketEvents.forEach((ev) => {
      this.socket.on(ev.name, ev.method);
    });
    // listen to all events
    this.socket.onAny((ev, data) => {
      SocketMessage.add(data.message);
    });
  }

  disconnect() {
    if (this.isConnected) {
      this.socket.disconnect();
    }
  }
}

const SocketConnection = {
  isConnected: false,
  rooms: {},
  connect: (roomId, userId) => {
    if (SocketConnection.rooms[roomId]) {
      return SocketConnection.rooms[roomId];
    }
    const manager = new SocketManager(roomId, userId);
    SocketConnection.rooms[roomId] = manager;
    return manager;
  },
  disconnect: (roomId) => {
    // no specific room mentioned... disconnect all
    if (!roomId) {
      Object.keys(SocketConnection.rooms).forEach((key) => {
        SocketConnection.rooms[key].disconnect();
      });
    } else {
      SocketConnection.rooms[roomId].disconnect();
    }
  },
};

export default SocketConnection;
