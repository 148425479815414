import React, { useState, useEffect } from 'react';
import SocketMessage from 'shared/services/SocketMessage';
import Toast from './Toast';

function TeamToast() {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const idx = SocketMessage.sub((messages) => {
      if (!messages.length) {
        return;
      }
      const showMessage = messages[messages.length - 1];
      setMessage(showMessage);
      setIsOpen(true);
    });
    return () => {
      SocketMessage.unsub(idx);
    };
  }, []);
  return (
    <Toast
      open={isOpen}
      onClose={onClose}
      text={<span dangerouslySetInnerHTML={{ __html: message }} />}
    />
  );
}

export default TeamToast;
