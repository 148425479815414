import http from './http';

export default class ProductService {
  static addProduct(data) {
    return http.post('/product', data);
  }
  static getProductCategories() {
    return http.get('/categories');
  }
  static getProduct(productId) {
    return http.get(`/product/${productId}`);
  }
  static deleteProduct(productId) {
    return http.delete(`/product/${productId}`);
  }
  static updateProduct(product) {
    return http.put('/product', product);
  }
  static removeImage(imageId, productId) {
    return http.delete(`/product/${productId}/image/${imageId}`)
  }
}